/* global Component */
class paginationComponent extends Component {

    static name() {
        return "paginationComponent";
    }

    static componentName() {
        return "paginationComponent";
    }

    getProps() {
        return ['currentView','items'];
    }

    mounted() {
        return function () {
            if(this.$route.query.page)
                this.$store.state.pageOffset = this.$route.query.page - 1 ;
        };
    }

    data() {
        return {
            counter:0,
            itemsInView:10
        };
    }

    getComputed() {
        return {
            itemsPerPage:function (){
                if(this.mustByRender === true)
                    this.mustByRender = false;
                return this.$store.getters.getItemPerPage;
            },
            currentOffSet:function (){
                if(this.mustByRender === true)
                    this.mustByRender = false;
                return this.$store.getters.getOffset;
            },
            initView:function (){
                if(this.mustByRender === true)
                    this.mustByRender = false;
                if(this.$store.getters.getOffset < this.itemsInView)
                    return  0;
                else
                    return 0;
            },
            itemsCount:function () {
                if(this.mustByRender === true)
                    this.mustByRender = false;
                if(this.items) {
                    switch(this.currentView) {
                        case 'customerGifts':
                            return this.$store.getters.getCustomerGifts.length;
                        case 'favorites':
                            return this.$store.getters.getFavoritesItems.length;
                        case 'suggestions':
                            return this.$store.getters.getSuggestions.length;
                        default:
                            return this.items.filter(x => x.visible).length;
                    }
                }
                return 0;
            },
            offsetLength :function (){
                if (this.itemsCount < this.itemsPerPage)
                    return 1;
                return this.itemsCount % this.itemsPerPage == 0 ? this.itemsCount / this.itemsPerPage : parseInt(this.itemsCount / this.itemsPerPage) + 1;
            },
            offStore:function (){
                let self = this;
                this.counter = 0;
                return Array.from({length: this.offsetLength}, () => self.counter++);
            }
        };
    }

    getMethods() {
        return {
            changeOffset:this.changeOffset,
            itemUpdated:this.itemUpdated
        };
    }

    changeOffset(offset){
        if(offset < 0)
            offset = 0;
        if(offset > this.offsetLength - 1)
            offset = this.offsetLength - 1;
        window.scrollTo(0, 0);
        this.$store.state.pageOffset = offset;
        if(offset != this.$route.query.page - 1) {
            this.$router.push({
                params:this.$route.params,
                query:{
                    category: this.$route.query.category,
                    page:offset + 1
                }
            });
        }
    }
    itemUpdated(){
        this.mustByRender = true;
    }

    getTemplate() {
        return `<nav aria-label="Page navigation">
                    <ul class="pagination justify-content-center">
                        <li class="page-item disabled" >
                            <a href="#" class="page-link">
                                {{itemsCount}}
                                <template v-if="itemsCount == 1">{{tr('Item')}}</template>
                                <template v-else>{{tr('Items')}}</template>
                            </a>
                        </li>
                        <li :class="{'page-item':true,'disabled':currentOffSet==0}">
                            <a href="#" :class="{'page-link':true}" @click.stop.prevent="changeOffset(currentOffSet-1)" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                        </li>
                        <template v-if="currentOffSet<itemsInView">
                            <template v-for="offset of offStore.slice(0,itemsInView)">
                                <li :class="{'page-item':true,'active':offset==currentOffSet ,'waves-effect':true }" >
                                    <a href="#" :class="{'page-link':true, active:currentOffSet==offset}" @click.stop.prevent="changeOffset(offset)">{{offset+1}}</a>
                                </li>
                            </template>
                        </template>
                        <template v-else-if="currentOffSet > offStore.length -itemsInView">
                            <template v-for="offset of offStore.slice((offStore.length -itemsInView),(offStore.length))">
                                <li :class="{'page-item':true,'active':offset==currentOffSet ,'waves-effect':true }" >
                                    <a href="#" :class="{'page-link':true, active:currentOffSet==offset}" @click.stop.prevent="changeOffset(offset)">{{offset+1}}</a>
                                </li>
                            </template>
                        </template>
                        <template v-else>
                            <template v-for="offset of offStore.slice(currentOffSet-1,(currentOffSet+itemsInView))">
                                <li :class="{'page-item':true,'active':offset==currentOffSet ,'waves-effect':true }" >
                                    <a href="#" :class="{'page-link':true, active:currentOffSet==offset}" @click.stop.prevent="changeOffset(offset)">{{offset+1}}</a>
                                </li>
                            </template>
                        </template>
                        <li :class="{'page-item':true,'disabled':currentOffSet==offsetLength-1}">
                            <a href="#" :class="{'page-link':true}" @click.stop.prevent="changeOffset(currentOffSet+1)" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>`;
    }
}

paginationComponent.registerComponent();